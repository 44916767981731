<template>
  <el-dialog v-dialog-drag :title="title" :width="width" :visible="visible" :close-on-click-modal="false"
    @close="handleClose">
    <div class="xls-import">
      <el-upload drag ref="uploadXls" accept=".xls,.xlsx" :auto-upload="false" :limit="1" :show-file-list="true"
        :action="xlsTabData.uploadUrl" :headers="xlsTabData.headers" :on-success="uploadXlsSuccess"
        :on-error="uploadXlsError">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或<em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">只能上传excel文件。</div>
      </el-upload>
      <div class="btn-wrap">
        <el-button type="success" size="small" :loading="xlsTabData.loading1" @click="handleDownXls">下载模板</el-button>
        <el-button type="primary" size="small" :loading="xlsTabData.loading2"
          @click="handleImportByXls">导入数据</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import { baseURL, successCode } from "@/config";
export default {
  name: "ExcelImport",
  data() {
    return {
      visible: false,
      width: "600px",
      title: "导入",
      xlsTabData: {
        loading1: false,
        loading2: false,
        uploadUrl: '',
        templateUrl: '',
        templateName: '',
        headers: {
          Authorization: "Bearer " + this.$store.getters["user/accessToken"],
        },
      },
    };
  },
  methods: {
    initDialog(title, uploadUrl, templateUrl, templateName) {
      this.xlsTabData.uploadUrl = baseURL + uploadUrl;
      this.xlsTabData.templateUrl = templateUrl
      this.xlsTabData.templateName = templateName
      this.title = title;
      this.visible = true;
    },
    handleClose() {
      this.visible = false;
    },
    async handleDownXls() {
      var that = this;
      var a = document.createElement("a");
      a.href = that.xlsTabData.templateUrl;
      a.download = that.xlsTabData.templateName;
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    handleImportByXls() {
      if (this.$refs.uploadXls.uploadFiles.length<=0) {
        this.$message({
          type: "error",
          message: "请选择要上传的文件",
        });
        return;
      }
      this.xlsTabData.loading2 = true;
      this.$refs.uploadXls.submit();
    },
    uploadXlsSuccess(res) {
      var that = this;
      if (successCode.includes(res.code)) {
        that.handleClose();
        that.$alert(res.msg, "导入结果", {
          dangerouslyUseHTMLString: true,
        });
        if(that.$listeners['handleCallback']){//如果有提供执行$emit这里
          that.$emit("handleCallback", res);
        }
      } else {
        that.$message({
          type: "error",
          message: res.msg,
          dangerouslyUseHTMLString: true,
        });
      }
      that.$refs.uploadXls.clearFiles();
      that.xlsTabData.loading2 = false;
    },
    uploadXlsError() {
      this.$message({
        type: "error",
        message: "导入失败",
      });
      this.xlsTabData.loading2 = false;
    },
  },
};
</script>
<style scoped>
.xls-import {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.xls-import .btn-wrap {
  margin-top: 10px;
}
</style>
